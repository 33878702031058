<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-toko-by-code v-model:value="toko" class="mr-2" />
        <filter-program v-model:value="program" class="mt-2 mr-2" />
        <filter-loyalty-point-type class="mt-2 mr-2" :mode="null" v-model:value="point" />
        <filter-kuartal v-model:value="quartal" :mode="null" class="mt-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-status-poin v-model:value="statusPoin" :mode="null" class="mt-2 mr-2" />
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2 mr-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mb-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Download Excel" type="primary" :loading="isDownloading" @click="fetchXlsx">
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #action="{record}">
          <span>
            <a-tooltip title="Lihat Histori Poin">
              <a-button @click="lihatPoin(record)" class="button" size="small">
                <i class="fe fe-eye" />
              </a-button>
            </a-tooltip>
            <a-tooltip title="Edit Histori Poin">
              <a-button v-if="record.points < 0" disabled="true" class="button" size="small">
                <i class="fe fe-edit" />
              </a-button>
              <a-button v-else  @click="editPoin(record)" class="button" size="small">
                <i class="fe fe-edit" />
              </a-button>
            </a-tooltip>
          </span>
        </template>
      </md-table>
    </div>
    <m-modal
      v-if="visible"
      v-model:visible="visible"
      :model="model"
      @handleOk="handleOk"
      :destroy-on-close="true"
    ></m-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import acolumns from './columns'
import MModal from './modal'
import FilterLoyaltyPointType from '@/components/filter/FilterLoyaltyPointType'
import FilterTokoByCode from '@/components/filter/FilterTokoByCode'
import FilterProgram from '@/components/filter/FilterProgram'
import FilterKuartal from '@/components/filter/FilterKuartal'
import FilterStatusPoin from '@/components/filter/FilterStatusPoin'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'

export default defineComponent({
  components: {
    MModal,
    FilterLoyaltyPointType,
    FilterTokoByCode,
    FilterProgram,
    FilterKuartal,
    FilterStatusPoin,
  },
  emits: ['handleOk'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      point: null,
      quartal: null,
      statusPoin: null,
      toko: [],
      program: [],
      q: null,
      params: [],
    })

    const visible = ref(false)

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const params = ref({})
    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        point: state.point,
        quartal: state.quartal,
        status_poin: state.statusPoin,
        kode_toko: state.toko,
        program: state.program,
        q: state.q,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/loyalty-point-histories', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/loyalty-point-histories', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `point-histories_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    const lihatPoin = record => {
      visible.value = true
      model.record = record
      model.readOnly = true
    }

    const editPoin = record => {
      visible.value = true
      model.record = record
      model.readOnly = false
    }

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      handleOk,
      fetchXlsx,
      columns,
      handleChange,
      handleTableChange,
      model,
      ...toRefs(state),
      search,
      errorMessage,
      moment,
      lihatPoin,
      editPoin,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
